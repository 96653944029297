<template>
    <Mymodal
      :show="show"
      @close="close"
      @sure="close"
    >
      <div class="title">填写收获地址</div>
      <div class="content">
        <div class="label">
          <span>收件人</span>
          <input type="text" placeholder="请输入" v-model="info.name" />
        </div>
        <div class="label">
          <span>收货地址</span>
          <input type="text" placeholder="请输入" v-model="info.address" />
        </div>
        <div class="label">
          <span>手机号</span>
          <input type="text" placeholder="请输入" v-model="info.phoneNum" />
        </div>
      </div>
      <div class="sure" @click="confirm">确认</div>
  
    </Mymodal>
  </template>
  <script>
    import { mapState } from "vuex"
    export default {
      name: '',
      computed:{
        ...mapState({
          show:(state)=>state.dialog === 'address'
        })
      },
      data(){
        return {
          info: {
            name: '',
            address: '',
            phoneNum: ''
          }
        }
      },
      watch: {
        show(newVal) {
          if(newVal) {
            this.info = localStorage.getItem('addressDetail') ? JSON.parse(localStorage.getItem('addressDetail')) : this.info
          }
        }
      },
      methods:{
        confirm() {
          if(!this.info.name && this.info.name.trim().length === 0) {
            this.$toast('请填写收件人姓名')
            return
          } else if(this.info.address.trim().length === 0) {
            this.$toast('请填写收获地址')
            return
          } else if(this.info.phoneNum.trim().length === 0) {
            this.$toast('请填写手机号码')
            return
          }
          localStorage.setItem('addressDetail', JSON.stringify(this.info))
          this.close()
        },
        close(){
          this.$store.commit('SETVAL',{dialog:''})
        }
      },
      mounted(){
      },
    }
  </script>
  <style scoped lang='less'>
    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .label {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        input {
          width: 68%;
          height: 80px;
          border-radius: 15px;
          outline: none;
          border: 1px solid #000;
          padding: 0 20px;
          box-sizing: border-box;
        }
      }
    }
    .sure {
      .set-bg(562px,105px,'s-btn-bg.png');
      font-size: 54px;
      color: #FFFFFF;
      line-height: 105px;
      text-align: center;
      margin: 50px auto 0 auto;
    }
  </style>